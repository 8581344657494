@font-face {
  font-family: "Argent Pixel CF";
  src: url(./assets/fonts/ArgentPixelCF-Regular.woff2) format("woff2"),
    url(./assets/fonts/ArgentPixelCF-Regular.woff) format("woff");
  font-style: normal;
}

@font-face {
  font-family: "Argent Pixel CF";
  src: url(./assets/fonts/ArgentPixelCF-Italic.woff2) format("woff2"),
    url(./assets/fonts/ArgentPixelCF-Italic.woff) format("woff");
  font-style: italic;
}

@font-face {
  font-family: "Roboto Mono";
  src: url(./assets/fonts/RobotoMono-Regular.woff2) format("woff2"),
    url(./assets/fonts/RobotoMono-Regular.woff) format("woff");
  font-style: normal;
}

:root {
  --font-primary: "Argent Pixel CF", serif;
  --colour-primary: #f7152b;
  --colour-secondary: #2e2edc;
  --colour-tertiary: #2ee028;
  --colour-base: #310f0f;
  --colour-background: #f1f0d9;
}

* {
  box-sizing: border-box;
}

.App {
  margin: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
}

.title {
  display: block;
  text-align: center;
  color: var(--colour-primary);
  font-size: 48px;
  line-height: 1.15;
  margin: 26px;
  text-decoration: none;

  @media (min-width: 600px) {
    font-size: 64px;
  }
}

body {
  background: var(--colour-background);
  color: var(--colour-base);
  max-width: 900px;
  margin: auto;

  font-family: var(--font-primary);
  text-rendering: optimizeSpeed;
  font-smooth: never;
  line-height: 1.5;
  font-size: 18px;
}

input,
button {
  border: none;
  padding: 0.25em;
  margin: 0 0.25em;
  color: var(--colour-base);
}

button {
  background-color: var(--colour-primary);
  color: white;
  font-weight: bold;

  &.arrow::after {
    content: " -->";
  }
}

input:focus,
button:focus {
  background-color: #eee;
  outline: 2px solid black;
}

a {
  color: var(--colour-secondary);
}

h1,
h2,
h3 {
  a {
    text-decoration: none;
    color: currentColor;
  }
}

hr {
  border: 1px dashed var(--colour-base);
}

abbr {
  text-decoration: none;
  border-bottom: 1px solid var(--colour-primary);
}
